import FeatureLayer from "@arcgis/core/layers/FeatureLayer"
import * as renderInfo from "./renderInfo"

export const solar = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://opencity.vplanas.lt/arcgis/rest/services/Ivairus/Solar_radation_2020/MapServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
   // renderer: renderInfo.extentOutline,
    visible: false
})

export const socialServices = new FeatureLayer({
    url: "https://opencity.vplanas.lt/arcgis/rest/services/VilniusData_portalas/Soc_paslaugu_istaigos/MapServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const health = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Sveikatos_%C4%AEstaigos/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const educationEraly = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Ikimokyklinio_ugdymo_%C4%AFstaigos/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const educationGeneral = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Mokyklos_Vilnius/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})


export const constructionAreas = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Pastatai_statyba_kvartalai/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const constructionNTR = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Pastatai_NTR_Seniunaitijos/FeatureServer/13",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const realEstate = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Vilniaus_m_Nekilnojamas_turtas/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const healthReach = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Paslaugos_pasiekiamumas_unikalios/FeatureServer/442",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const areas = new FeatureLayer({
   // effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Seniunaitijos/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: true
})

export const areasPopulation = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Gyventojai_seni%C5%ABnaitijose/FeatureServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const areasPopulationConc = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Gyventoj%C5%B3_koncentracija_seni%C5%ABnaitijose/FeatureServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })


export const bikesAreas = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/4",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const sidewalkAreas = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/5",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const bikes2022 = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/3",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const bikes2023 = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/2",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const bikes2024 = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/1",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const bikes2025 = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const parkingDisabled = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://atviras.vplanas.lt/arcgis/rest/services/Parkingas/Neigaliuju_parkavimo_vietos/MapServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const parkingEV = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://atviras.vplanas.lt/arcgis/rest/services/Parkingas/Elektromobiliu_parkavimo_vietos_stoteles/MapServer/1",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const chargingEV = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://atviras.vplanas.lt/arcgis/rest/services/Parkingas/Elektromobiliu_parkavimo_vietos_stoteles/MapServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })
/*


https://opencity.vplanas.lt/arcgis/rest/services//Ivairus/Solar_radation_2020/MapServer - solar

Sveikatos_istaigos
https://opencity.vplanas.lt/portal/home/item.html?id=6670bcebe0bc4271b7f28c9ff45541d0

Ikimokyklinio_ugdymo_istaigos
https://opencity.vplanas.lt/portal/home/item.html?id=3805d160713043b0ac7518d12e99c65e

Pastatai_statyba_kvartalai
https://opencity.vplanas.lt/portal/home/item.html?id=356adfce37604432a67092f365794821

Seniunaitijos
https://opencity.vplanas.lt/portal/home/item.html?id=ebcc318aead544afb8de9164f8859b76

*/
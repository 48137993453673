import React, { useEffect, useState } from 'react'
import { GraphLine } from '../widgets/graphLine'
import { GarphBar } from '../widgets/garphBar'
import { CordInput } from '../widgets/cordInput'
import { DynamicTable } from '../widgets/dynamicTable'

import { areas, svieslentes, sviesoforai, bikesAreas, sidewalkAreas, bikes2022, bikes2023, bikes2024, bikes2025, parkingDisabled, parkingEV, chargingEV } from '../../services/featureLayersOld'
import { useContext } from "react";
import { mapContext } from '../mapContext'
import { findNearestFromList, queryFeaturesOverLimit } from '../helper/helper'
import { GraphPie } from '../widgets/graphPie'
import { getDistance } from 'geolib'

export const JudumasTab = () => {
    const context = useContext(mapContext);
    const [initialRender, setInitialRender] = useState(0);
    
    const [areaStatSvieslentes, setareaStatSvieslentes] = useState([]);
    const [areaStatSviesoforai, setareaStatSviesoforai] = useState([]);
    const [areaBikeStat, setAreaBikeSat] = useState([]);
    const [areaPavementStat, setAreaPavementSat] = useState([]);

    const [location, setLocation] = useState(null)

    const [flag, setFlag] = useState(true)


    const [tableData, setTableData] = useState([
        {string: "Jūsų seniūnaitija: ", data: "-"},

        {string: ""},
        {string: "Artimiausias:"},
        {string: "Garsinis šviesoforas ", data: "-", suffix:"m"}, 
        {string: "Švieslentė ", data: "-", suffix:"m"},
        {string: "Asmenų su negalia parkavimosi vieta ", data: "-", suffix:"m"}, 
        {string: "Elektromobilio įkrovimo stotelė ", data: "-", suffix:"m"},
        {string: "Elektromobilio parkavimosi vieta ", data: "-", suffix:"m"}, 

        {string: ""},
        {string: "Seniūnaitijos statistika:"},
        {string: "Jūsų seniūnaitija yra ", data: "-/189", suffix:" seniūnaitijų pagal garsiniu šviesoforų skaičių"},
        {string: "Jūsų seniūnaitija yra ", data: "-/189", suffix:" seniūnaitijų pagal švieslenčių skaičių"},
        {string: "Jūsų seniūnaitija yra ", data: "-/189", suffix:" seniūnaitijų pagal dviračių takų ilgį metrais"}, 
        {string: "Jūsų seniūnaitija yra ", data: "-/189", suffix:" seniūnaitijų pagal šaligatvių ilgį metrais"}
    ])

    let dataBar = {
        labels: [],
        datasets: []
      }

    const [lineGraphData, setlineGraphData] = useState(dataBar)

    useEffect(() => {
        if (initialRender === 1) {
          return
        }
        let query = areas.createQuery();


        
        
        //query.geometry = context.state.clickEvent.ground.mapPoint;  // the point location of the pointer
        //query.spatialRelationship = "intersects";  // this is the default
        //query.returnGeometry = true;
        
        query.outFields = [ "*" ];

        query.where = "1=1"


        query = areas.createQuery();
        query.geometry = context.state.clickEvent.ground.mapPoint;  // the point location of the pointer
        query.spatialRelationship = "intersects";  // this is the default
        query.returnGeometry = true;
        query.outFields = [ "*" ];
        areas.queryFeatures(query)
        .then(function(response){

            setLocation(response.features[0].attributes.seniunait)
            let tempTable = tableData
            
            tempTable[0].data = response.features[0].attributes.seniunija + ", " + response.features[0].attributes.seniunait
            setTableData(tempTable)
            query = bikes2024.createQuery()
            query.where =  "1=1"
            query.outFields = [ "*" ];

            let objDataLineGraph = {
                "2022" : 0,
                "2023" : 0,
                "2024" : 0,
                "2025" : 0,
            }

            queryFeaturesOverLimit(bikes2025, query).then((results) => {
                
                let filteredResults = results.filter((x) => {return x.attributes.Seniunait === response.features[0].attributes.seniunait})
                
                filteredResults.forEach(element => {
                    objDataLineGraph["2025"] += element.attributes.Shape__Length
                });
                
                queryFeaturesOverLimit(bikes2024, query).then((results) => {
                
                    let filteredResults = results.filter((x) => {return x.attributes.Seniunait === response.features[0].attributes.seniunait})
                    
                    filteredResults.forEach(element => {
                        objDataLineGraph["2024"] += element.attributes.Shape__Length
                       
                    });

                    queryFeaturesOverLimit(bikes2023, query).then((results) => {
                
                        let filteredResults = results.filter((x) => {return x.attributes.Seniunait === response.features[0].attributes.seniunait})
                        
                        filteredResults.forEach(element => {
                            objDataLineGraph["2023"] += element.attributes.Shape__Length
                            
                        });

                        queryFeaturesOverLimit(bikes2022, query).then((results) => {
                
                            let filteredResults = results.filter((x) => {return x.attributes.Seniunait === response.features[0].attributes.seniunait})
                            
                            filteredResults.forEach(element => {
                                objDataLineGraph["2022"] += element.attributes.Shape__Length

                                

                            });
                            let dataLine = {
                                labels:  Object.keys(objDataLineGraph),
                                datasets: [
                                  {
                                    label: 'Planuojamu dviračiu takų ilgis metrais',
                                    data: Object.values(objDataLineGraph),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.8)',
                                  }
                                ]
                            };
                            setlineGraphData(dataLine)
                        })
                        
                        
                    })

                    
                })
                
            })

            query.where =  "1=1"
            query.outFields = [ "*" ];

            queryFeaturesOverLimit(bikesAreas, query).then((results) => {
                let filteredResults = results.map(({attributes}) => ({area: attributes.Seniunait, length: attributes.Shape__Length}))

                var outputObj = filteredResults.reduce((finalObj, curObj) => {
                    if (!finalObj[curObj.area]) finalObj[curObj.area] = curObj.length  //<--- adding new user if do not exist in the object
                    else finalObj[curObj.area] += curObj.length; // <---- if already present, the add the sum
                    return finalObj;
                  }, {})
                  
                var output = Object.entries(outputObj).map((obj) => ({
                    area: obj[0], //<--- key of object as userId
                    length: obj[1]  //<--- value of object as sum
                }));


                setAreaBikeSat(output)
            })

            queryFeaturesOverLimit(sidewalkAreas, query).then((results) => {
                let filteredResults = results.map(({attributes}) => ({area: attributes.Seniunait, length: attributes.Shape__Length}))
                
                var outputObj = filteredResults.reduce((finalObj, curObj) => {
                    if (!finalObj[curObj.area]) finalObj[curObj.area] = curObj.length  //<--- adding new user if do not exist in the object
                    else finalObj[curObj.area] += curObj.length; // <---- if already present, the add the sum
                    return finalObj;
                  }, {})

                  
                  
                var output = Object.entries(outputObj).map((obj) => ({
                    area: obj[0], //<--- key of object as userId
                    length: obj[1]  //<--- value of object as sum
                }));

                setAreaPavementSat(output)
            })


            

            queryFeaturesOverLimit(svieslentes, query).then((results) => {
                let filteredResults = results.map(({attributes}) => ({area: attributes.Seniunait}))
                
                var outputObj = filteredResults.reduce((finalObj, curObj) => {
                    if (!finalObj[curObj.area]) finalObj[curObj.area] = 0  //<--- adding new user if do not exist in the object
                    else finalObj[curObj.area] += 1; // <---- if already present, the add the sum
                    return finalObj;
                  }, {})
                  
                var output = Object.entries(outputObj).map((obj) => ({
                    area: obj[0], //<--- key of object as userId
                    svieslentes: obj[1]  //<--- value of object as sum
                }));

                setareaStatSvieslentes(output)
            })

            queryFeaturesOverLimit(sviesoforai, query).then((results) => {
                let filteredResults = results.map(({attributes}) => ({area: attributes.Seniunait}))
                
                var outputObj = filteredResults.reduce((finalObj, curObj) => {
                    if (!finalObj[curObj.area]) finalObj[curObj.area] = 0  //<--- adding new user if do not exist in the object
                    else finalObj[curObj.area] += 1; // <---- if already present, the add the sum
                    return finalObj;
                  }, {})
                  
                var output = Object.entries(outputObj).map((obj) => ({
                    area: obj[0], //<--- key of object as userId
                    amount: obj[1]  //<--- value of object as sum
                }));

                setareaStatSviesoforai(output)
            })

            query = svieslentes.createQuery()
            
            let geometry = response.features[0].geometry

            query.geometry = geometry;  // the point location of the pointer
            query.spatialRelationship = "intersects";  // this is the default
            query.returnGeometry = true;
            query.outFields = [ "*" ];

            svieslentes.queryFeatures(query).then(function(response) {
               // console.log(response.features)

                let tempTable = tableData
                let longLatList = response.features.map(({geometry}) => ({latitude: geometry.latitude, longitude: geometry.longitude}))

                let closest = findNearestFromList({ 
                    latitude: context.state.clickEvent.ground.mapPoint.latitude.toFixed(6),  
                    longitude: context.state.clickEvent.ground.mapPoint.longitude.toFixed(6) },
                    longLatList)

                tempTable[4].data = closest
                setTableData(tempTable)
                
            })
      
            sviesoforai.queryFeatures(query).then(function(response) {
                let tempTable = tableData
                let longLatList = response.features.map(({geometry}) => ({latitude: geometry.latitude, longitude: geometry.longitude}))

                let closest = findNearestFromList({ 
                    latitude: context.state.clickEvent.ground.mapPoint.latitude.toFixed(6),  
                    longitude: context.state.clickEvent.ground.mapPoint.longitude.toFixed(6) },
                    longLatList)

                tempTable[3].data = closest
                setTableData(tempTable)
            
            })

            parkingDisabled.queryFeatures(query).then(function(response) {
                let tempTable = tableData
                let longLatList = response.features.map(({geometry}) => ({latitude: geometry.centroid.latitude, longitude: geometry.centroid.longitude}))

                let closest = findNearestFromList({ 
                    latitude: context.state.clickEvent.ground.mapPoint.latitude.toFixed(6),  
                    longitude: context.state.clickEvent.ground.mapPoint.longitude.toFixed(6) },
                    longLatList)

                tempTable[5].data = closest
                setTableData(tempTable)
            
            })

            chargingEV.queryFeatures(query).then(function(response) {
                let tempTable = tableData
                let longLatList = response.features.map(({geometry}) => ({latitude: geometry.latitude, longitude: geometry.longitude}))

                let closest = findNearestFromList({ 
                    latitude: context.state.clickEvent.ground.mapPoint.latitude.toFixed(6),  
                    longitude: context.state.clickEvent.ground.mapPoint.longitude.toFixed(6) },
                    longLatList)

                tempTable[6].data = closest
                setTableData(tempTable)
            
            })

            parkingEV.queryFeatures(query).then(function(response) {
                let tempTable = tableData
                let longLatList = response.features.map(({geometry}) => ({latitude: geometry.centroid.latitude, longitude: geometry.centroid.longitude}))

                let closest = findNearestFromList({ 
                    latitude: context.state.clickEvent.ground.mapPoint.latitude.toFixed(6),  
                    longitude: context.state.clickEvent.ground.mapPoint.longitude.toFixed(6) },
                    longLatList)

                tempTable[7].data = closest
                setTableData(tempTable)
            
            })

            setInitialRender(1)
        }); 
        
      },[initialRender])

      useEffect(() => {
        if (areaBikeStat.length === 0 || location === null) {
            return
        }
        let array = areaBikeStat.sort(({length:a}, {length:b}) => b-a);

        let index = array.findIndex(function(area) {
            return area.area == location
        });
        let tempData = tableData
        if (index === -1) {
            index = 188
        }
        tempData[12].data =  (index+1) + "/189"
        setTableData(tempData)
        setFlag(!flag)
      },[areaBikeStat])

      useEffect(() => {
        if (areaPavementStat.length === 0 || location === null) {
            return
        }
        let array =  areaPavementStat.sort(({length:a}, {length:b}) => b-a);
        let index = array.findIndex(function(area) {
            return area.area == location
        });
        let tempData = tableData
        if (index === -1) {
            index = 188
        }
        tempData[13].data = (index+1) + "/189"
        setTableData(tempData)
        setFlag(!flag)
      },[areaPavementStat])

      useEffect(() => {
        if (areaStatSvieslentes.length === 0 || location === null) {
            return
        }
        let array =  areaStatSvieslentes.sort(({svieslentes:a}, {svieslentes:b}) => b-a);
        let index = array.findIndex(function(area) {
            return area.area == location
        });
        let tempData = tableData
        if (index === -1) {
            index = 188
        }
        tempData[11].data =  (index+1) + "/189"

        setTableData(tempData)
        setFlag(!flag)

      },[areaStatSvieslentes])
        
        useEffect(() => {

        if (areaStatSviesoforai.length === 0 || location === null) {
            return
        }

        let array = areaStatSviesoforai.sort(({sviesoforai:a}, {sviesoforai:b}) => b-a);
        let index = array.findIndex(function(area) {
            return area.area == location
        });
        let tempData = tableData
        if (index === -1) {
            index = 188
        }
        tempData[10].data =  (index+1) + "/189"
        setTableData(tempData)
        setFlag(!flag)
      },[areaStatSviesoforai])

      useEffect(() => {
        setTableData(tableData)
      },[flag])





  return (
    <div>
        <div id={"judumasCordContainer"}>
            <CordInput></CordInput>
        </div>
        <div id={"judumasTableContainer"}>
            <DynamicTable dataArray={tableData}></DynamicTable>
        </div>
        <div id={"judumasGraphContainer"}>
            <GraphLine dataset = {lineGraphData} title = {""}></GraphLine>
        </div>
        
    </div>
  )
}


import React, { useEffect, useState } from 'react'
import { GraphLine } from '../widgets/graphLine'
import { GarphBar } from '../widgets/garphBar'
import { CordInput } from '../widgets/cordInput'
import { DynamicTable } from '../widgets/dynamicTable'

import { areas, educationEraly, educationGeneral } from '../../services/featureLayersOld'
import { useContext } from "react";
import { mapContext } from '../mapContext'
import { findNearestFromList } from '../helper/helper'

export const EducationTab = () => {
  const context = useContext(mapContext);
  const [initialRender, setInitialRender] = useState(0);
  const [schools, setSchools] = useState([]);
  const [preSchools, setPreSchools] = useState([]);

  const [tableData1, setTableData1] = useState([{string: "Ikimokyklinio ugdymo įstaigų kiekis:", data: 0, suffix: ""}, {string: "Bendrojo ugdymo įstaigų kiekis:", data: 0, suffix: ""}])
  const [tableData2, setTableData2] = useState([{string: "Iki darželio:", data: "-", suffix: "m"}, {string: "Iki mokyklos:", data: "-", suffix: "m"}])

  

  let dataLine = {
    labels:  [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [20, 50, 60, 80, -20, 0, 100],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
      },
      {
        label: 'Dataset 2',
        data: [120, 150, 160, 20, -120, 0, 10],
        borderColor: 'rgb(255, 159, 64)',
        backgroundColor: 'rgba(255, 159, 64, 0.8)',
      }
    ]
  };

  let dataBar = {
    labels: [],
    datasets: []
  }

  const [barGraphData, setbarGraphData] = useState(dataBar)
  const [barGraphData2, setbarGraphData2] = useState(dataBar)

  let graphTitle = {

    title: {
      display: true,
      text: "Bendrojo ugdymo įstaigos",
      color: "white",
      position: "top"
    }
    
  }

  let graphTitle2 = {

    title: {
      display: true,
      text: "Ikimokyklinio ugdymo įstaigos",
      color: "white",
      position: "top"
    }
    
  }

  function updateBarGraph() {

    //console.log(schools)

    let labelsLoc = schools.map(({attributes}) => (attributes.VARDAS))
    
    let datasetsAttending = schools.map(({attributes}) => (attributes.MOKSSK))
    let datasetsWaiting = schools.map(({attributes}) => (attributes.LAUKIA))
    let datasetsSize = schools.map(({attributes}) => (attributes.TALPA))
    //console.log(datasetsSize)
    //console.log(datasetsWaiting)

    

    let dataBar = {
      labels: labelsLoc,
      datasets: [{
        label: 'Vietų skaičius',
        data: datasetsSize,
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',

        ],
        borderColor: [
          'rgb(255, 99, 132)',
        ],
        borderWidth: 1
      }, 
      {
        label: 'Lanko',
        data: datasetsAttending,
        backgroundColor: [
          'rgba(255, 159, 64, 0.8)',

        ],
        borderColor: [
          'rgb(255, 159, 64)',
        ],
        borderWidth: 1
      },
      {
        label: 'Paraiškų skaičius',
        data: datasetsWaiting,
        backgroundColor: [
          'rgba(135,206,235, 0.8)'

        ],
        borderColor: [
          'rgb(135,206,235)'
        ],
        borderWidth: 1
      }
      ]
    };
    
    setbarGraphData(dataBar)
  }

  function updateBarGraphic2() {
    //let labelsLoc = ["Ikimokyklinio ugdymo įstaigos"]
    let labelsLoc = [""]

    let dataSetsAttendingSUM = 0
    preSchools.map(({attributes}) => (attributes.CHILDS_COUNT)).forEach(element => {
      if (element !== null && element !== undefined)
      {
        dataSetsAttendingSUM += parseInt(element)
      }
      
    })
    let datasetsFreeSpacesSum = 0
    preSchools.map(({attributes}) => (attributes.FREE_SPACE)).forEach(element => {
      if (element !== null && element !== undefined)
      {
        datasetsFreeSpacesSum += parseInt(element)
      }
    })
    let dataBar = {
      labels: labelsLoc,
      datasets: [{
        label: 'Laisvų vietų skaičius',
        data: [datasetsFreeSpacesSum],
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',

        ],
        borderColor: [
          'rgb(255, 99, 132)',
        ],
        borderWidth: 1
      }, 
      {
        label: 'Lanko',
        data: [dataSetsAttendingSUM],
        backgroundColor: [
          'rgba(255, 159, 64, 0.8)',

        ],
        borderColor: [
          'rgb(255, 159, 64)',
        ],
        borderWidth: 1
      }
      ]
    };

    setbarGraphData2(dataBar)
  }
  
  useEffect(() => {
    if (initialRender === 1) {
      return
    }
    let query = areas.createQuery();
    let table1 = [{string: "Seniūnaitija: ", data: null, suffix: "" }, ...tableData1]
    query.geometry = context.state.clickEvent.ground.mapPoint;  // the point location of the pointer
    query.spatialRelationship = "intersects";  // this is the default
    query.returnGeometry = true;
    query.outFields = [ "*" ];
  
    areas.queryFeatures(query)
      .then(function(response){
        //console.log(response.features[0])
        table1[0].data = response.features[0].attributes.seniunija + ", " + response.features[0].attributes.seniunait
        setTableData1([...table1])
        let queryEducationinArea = educationGeneral.createQuery()
        let geometry = response.features[0].geometry
        queryEducationinArea.geometry = geometry;  // the point location of the pointer
        queryEducationinArea.spatialRelationship = "intersects";  // this is the default
        queryEducationinArea.returnGeometry = true;
        queryEducationinArea.outFields = [ "*" ];

        educationGeneral.queryFeatures(queryEducationinArea).then(function(response) {
          setSchools(response.features)
          
        })

        educationEraly.queryFeatures(queryEducationinArea).then(function(response) {
          setPreSchools(response.features)
          
        })
        setInitialRender(1)
      }); 
      
  },[initialRender])

  useEffect(() => {
    if (schools.length === 0) {
      return
    } 
    let table1GenCount = tableData1[2]
   
    table1GenCount.data = schools.length

    setTableData1([tableData1[0], tableData1[1], table1GenCount])

    let schoolLongLatList = schools.map(({geometry}) => ({latitude: geometry.latitude, longitude: geometry.longitude}))
    let closestSchool = findNearestFromList({ 
      latitude: context.state.clickEvent.ground.mapPoint.latitude.toFixed(6),  
      longitude: context.state.clickEvent.ground.mapPoint.longitude.toFixed(6) },
      schoolLongLatList)

    let schoolTable2Update = tableData2[1]
    schoolTable2Update.data = closestSchool
    setTableData2([tableData2[0], schoolTable2Update])

    updateBarGraph()
  },[schools])

  useEffect(() => {
    if (preSchools.length === 0) {
      return
    } 
    //console.log(preSchools)
    let table1PreCount = tableData1[1]
    table1PreCount.data = preSchools.length

    setTableData1([tableData1[0], table1PreCount, tableData1[2]])
    
    let schoolLongLatList = preSchools.map(({geometry}) => ({latitude: geometry.latitude, longitude: geometry.longitude}))
    let closestSchool = findNearestFromList({ 
      latitude: context.state.clickEvent.ground.mapPoint.latitude.toFixed(6),  
      longitude: context.state.clickEvent.ground.mapPoint.longitude.toFixed(6) },
      schoolLongLatList)

    let schoolTable2Update = tableData2[0]
    schoolTable2Update.data = closestSchool
    setTableData2([schoolTable2Update, tableData2[1]])

    updateBarGraphic2()
  },[preSchools])

  return (
    <div id={"educationContainer"}>
      
      <div id="educationLineGraph">
      <GarphBar dataset={barGraphData2} indexAxis={'x'} title={graphTitle2} ></GarphBar>
      </div>
      
      <div id="educationBarGraph">
        <GarphBar dataset={barGraphData} indexAxis={'y'} title={graphTitle}></GarphBar>
      </div>
      <div id="educationCoordBox">
        <CordInput></CordInput>
      </div>
      
      <div id={"educationBox1"}>
        <DynamicTable dataArray={tableData1}></DynamicTable>
      </div>
      <div id={"educationBox2"}>
        <DynamicTable dataArray={tableData2}></DynamicTable>
      </div>
      
      
    </div>
  )
}

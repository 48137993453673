import React, {useEffect, useState} from 'react'
import "../styles/mapViewer.css";

import { EducationTab } from './tabs/educationTab';
import { areas } from '../services/featureLayersOld';

import closeicon from "../assets/closeicon.png";
import homeicon from "../assets/homeicon.png";
import { EnergyTab } from './tabs/energyTab';
import { PlanningTab } from './tabs/planningTab';
import { HealthTab } from './tabs/healthTab';
import { JudumasTab } from './tabs/judumasTab';
import { SocialTab } from './tabs/socialTab';




function switchTab(tab, mapContext) {
    mapContext.dispatch({
        tab: tab
    });
}

function tabButtons(context) {

    function waitForElm(selector) {
        return new Promise((resolve) => {
          if (document.querySelector(selector)) {
            return resolve(document.querySelectorAll(selector));
          }
    
          const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
              resolve(document.querySelectorAll(selector));
              observer.disconnect();
            }
          });
    
          observer.observe(document.body, {
            childList: true,
            subtree: true,
          });
        });
      }

    waitForElm('.defaultTabBorder').then((button) => {
        button.forEach(element => {
            element.addEventListener("click",function(){
              button.forEach(nav => nav.classList.remove("ActiveBtn"))
              this.classList.add("ActiveBtn")
            })
          })
      });

    return (
        <>
        <div class="tabA">
            <div  class="tabB inactiveTab" onClick={() => {
            //switchTab()   
            }}>
                Ekonominė plėtra
            </div>
        </div>
        <div class="tabA">
            <div  class="tabB defaultTabBorder ActiveBtn" onClick={() => {
            switchTab("education", context)   
            }}>
                Švietimas
            </div>
        </div>
        <div class="tabA">
            <div  class="tabB defaultTabBorder" onClick={() => {
            switchTab("planning", context)   
            }}>
                Planavimas
            </div>
        </div>
        <div class="tabA">
            <div  class="tabB defaultTabBorder" onClick={() => {
            switchTab("energy", context)  
            }}>
                Energetika
            </div>
        </div>
        <div class="tabA">
            <div  class="tabB  inactiveTab" onClick={() => {
            //switchTab()   
            }}>
                Saugumas ir apsauga
            </div>
        </div>
        <div class="tabA">
            <div  class="tabB defaultTabBorder" onClick={() => {
            switchTab("social", context)   
            }}>
                Socialinė apsauga
            </div>
        </div>
        <div class="tabA">
            <div  class="tabB defaultTabBorder" onClick={() => {
            switchTab("health", context)  
            }}>
                Sveikata ir sveikatingumas
            </div>
        </div>
        <div class="tabA">
            <div  class="tabB defaultTabBorder" onClick={() => {
            switchTab("judumas", context)   
            }}>
                Judumas
            </div>
        </div>
        <div class="tabA">
            <div  class="tabB inactiveTab" onClick={() => {
            //switchTab()   
            }}>
                Kultūra
            </div>
        </div>
        </>
    )
}

export default function Popup ({mapContext}) {
    const [initialRender, setInitialRender] = useState(0);

    

    useEffect(() => {
        if (initialRender === 1) {
          return
        }
        
        mapContext.dispatch({
            //1 - flag for loading
            areaSelected: 1,
        });
        let query = areas.createQuery();
        query.geometry = mapContext.state.clickEvent.ground.mapPoint;  // the point location of the pointer
        query.spatialRelationship = "intersects";  // this is the default
        query.returnGeometry = true;
        query.outFields = [ "*" ];
      
        areas.queryFeatures(query)
          .then(function(response){
            mapContext.dispatch({
                areaSelected: response.features[0],
            });
            
            setInitialRender(1)
          }); 
      },[initialRender])

    const handleParentClick = event => {
        event.preventDefault();
    
        if (event.target === event.currentTarget) {
            mapContext.dispatch({
                popup: !mapContext.state.popup,
            });
        }
      };

      

      function returnTabBody() {
        if (mapContext.state.tab === "education") {
            return (
                <EducationTab></EducationTab>
            )
        } else if (mapContext.state.tab === "energy") {
            return (
                <EnergyTab></EnergyTab>
            )
        } else if (mapContext.state.tab === "planning") {
            return (
                <PlanningTab></PlanningTab>
            )
        } else if (mapContext.state.tab === "health") {
            return (
                <HealthTab></HealthTab>
            )
        } else if (mapContext.state.tab === "judumas") {
            return (
                <JudumasTab></JudumasTab>
            )
        } else if (mapContext.state.tab === "social") {
            return (
                <SocialTab></SocialTab>
            )
        }else {
            return(
                <>NOTAB</>
            )
        }
      }
//<img class="homeButton" src={homeicon} onClick={() => {switchHome()}} />
  return (
    <div class="districtChartsPopup"onClick={handleParentClick}>
		<div class="popupPadding" onClick={handleParentClick}>
			<div class="rotation3D" onClick={handleParentClick}>
				<div class="infoBody">
					<div class="tabsRow" >
						<div class="tabContainer">
							<div class="remainingSpaceAfterTabs redBottomBorder">
							
						    </div>
                            {tabButtons(mapContext)}
							
						</div>
						<div class="remainingSpaceAfterTabs redBottomBorder">
							<img class="exitButton" src={closeicon} onClick={() => {
                                mapContext.dispatch({
                                    popup: !mapContext.state.popup,
                                });
                            }}/>
						</div>
					</div>
					<div class="tabBody selectedTabBody selectedTabBorder">
						{returnTabBody()}
						<div class="tabBodySpacing">
							
						</div>
					</div>
					<div class="source">

					</div>
				</div>
			</div>
		</div>
		
	</div>
  )
}

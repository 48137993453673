import React, { useEffect, useState } from 'react'
import { GraphLine } from '../widgets/graphLine'
import { GarphBar } from '../widgets/garphBar'
import { CordInput } from '../widgets/cordInput'
import { DynamicTable } from '../widgets/dynamicTable'

import { areas, healthReach, health } from '../../services/featureLayersOld'
import { useContext } from "react";
import { mapContext } from '../mapContext'
import { findNearestFromList, queryFeaturesOverLimit } from '../helper/helper'
import { GraphPie } from '../widgets/graphPie'
import { getDistance } from 'geolib'




export const HealthTab = () => {
    const context = useContext(mapContext);
    const [initialRender, setInitialRender] = useState(0);

    const [healtServices, setHealthServices] = useState([]);
    const [healtBuildings, setHealthBuildings] = useState([]);

    const [location, setLocation] = useState(null)
    const [locationcord, setLocationCoord] = useState(null)

    const [barGraphDatasets, setbarGraphDatasets] = useState([])
    const [barGraphSelected, setBarGraphSelected] = useState(0);

    const [tableData1, setTableData1] = useState([{string: "Sveikatos įstaigos"}])

    let dataBar = {
        labels: [],
        datasets: []
      }

    const [barGraphData, setbarGraphData] = useState(dataBar)

    

    function waitForElm(selector) {
        return new Promise((resolve) => {
          if (document.querySelector(selector)) {
            return resolve(document.querySelectorAll(selector));
          }
    
          const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
              resolve(document.querySelectorAll(selector));
              observer.disconnect();
            }
          });
    
          observer.observe(document.body, {
            childList: true,
            subtree: true,
          });
        });
      }

    waitForElm('.healthButton').then((button) => {
        button.forEach(element => {
            element.addEventListener("click",function(){
              button.forEach(nav => nav.classList.remove("ActiveBtn"))
              this.classList.add("ActiveBtn")
            })
          })
      });


    function updateGraphData() {
        //console.log(healtServices)
        let healthDataSet = []
        let healthReach = healtServices.filter(servisce => servisce.attributes).map(({attributes}) => ([attributes.pas_sk_i, attributes.ii_pas_sk_i, attributes.iii_pas_sk_i, attributes.v_pas_sk_i, attributes.viii_pas_sk_i]))
        healthDataSet.push(healthReach)

        healthReach = healtServices.filter(servisce => servisce.attributes).map(({attributes}) => ([attributes.pas_sk_ii, attributes.ii_pas_sk_ii, attributes.iii_pas_sk_ii, attributes.v_pas_sk_ii, attributes.viii_pas_sk_ii]))
        healthDataSet.push(healthReach)

        healthReach = healtServices.filter(servisce => servisce.attributes).map(({attributes}) => ([attributes.pas_sk_iii, attributes.ii_pas_sk_iii, attributes.iii_pas_sk_iii, attributes.v_pas_sk_iii, attributes.viii_pas_sk_iii]))
        healthDataSet.push(healthReach)

        healthReach = healtServices.filter(servisce => servisce.attributes).map(({attributes}) => ([attributes.pas_sk_iv, attributes.ii_pas_sk_iv, attributes.iii_pas_sk_iv, attributes.v_pas_sk_iv, attributes.viii_pas_sk_iv]))
        healthDataSet.push(healthReach)

        healthReach = healtServices.filter(servisce => servisce.attributes).map(({attributes}) => ([attributes.pas_sk_v, attributes.ii_pas_sk_v, attributes.iii_pas_sk_v, attributes.v_pas_sk_v, attributes.viii_pas_sk_v]))
        healthDataSet.push(healthReach)

        healthReach = healtServices.filter(servisce => servisce.attributes).map(({attributes}) => ([attributes.pas_sk_vi, attributes.ii_pas_sk_vi, attributes.iii_pas_sk_vi, attributes.v_pas_sk_vi, attributes.viii_pas_sk_vi]))
        healthDataSet.push(healthReach)

        healthReach = healtServices.filter(servisce => servisce.attributes).map(({attributes}) => ([attributes.pas_sk_vii, attributes.ii_pas_sk_vii, attributes.iii_pas_sk_vii, attributes.v_pas_sk_vii, attributes.viii_pas_sk_vii]))
        healthDataSet.push(healthReach)

        healthReach = healtServices.filter(servisce => servisce.attributes).map(({attributes}) => ([attributes.pas_sk_viii, attributes.ii_pas_sk_viii, attributes.iii_pas_sk_viii, attributes.v_pas_sk_viii, attributes.viii_pas_sk_viii]))
        healthDataSet.push(healthReach)

        setbarGraphDatasets(healthDataSet)
    }

    useEffect(() => {
        if (barGraphDatasets.length === 0 )
        {
            return
        }
        
        let labelsLoc = [
            "0-1km",
            "0-2km",
            "0-3km",
            "0-5km",
            "0-8km"
        ]
        let dataLabel = [
            "Bendrosios asmens sveikatos priežūros paslaugos",
            "Tyrimai ir procedūros",
            "Dantų protezavimo paslaugos",
            "Dienos stacionaro paslaugos",
            "Greitoji medicinos pagalba",
            "I lygis",
            "II lygis",
            "Prevencinės paslaugos"
        ]
        //console.log(labelsLoc)

        let dataBar = {
          labels: [dataLabel[barGraphSelected]],
          datasets: [{
            label: labelsLoc[0],
            data:[barGraphDatasets[barGraphSelected][0][0]],
            backgroundColor: [
                'rgb(255, 99, 132, 0.8)'
    
            ],
            borderColor: [
                'rgb(255, 99, 132)'
            ],
            borderWidth: 1
          },
          {
            label: labelsLoc[1],
            data:[barGraphDatasets[barGraphSelected][0][1]],
            backgroundColor: [

                'rgb(54, 162, 235, 0.8)'
    
            ],
            borderColor: [
                'rgb(54, 162, 235)'
            ],
            borderWidth: 1
          },
          {
            label: labelsLoc[2],
            data:[barGraphDatasets[barGraphSelected][0][2]],
            backgroundColor: [
                'rgb(255, 205, 132, 0.8)'
    
            ],
            borderColor: [
                'rgb(255, 205, 132)'
            ],
            borderWidth: 1
          },
          {
            label: labelsLoc[3],
            data:[barGraphDatasets[barGraphSelected][0][3]],
            backgroundColor: [
                'rgb(178, 222, 39, 0.8)'
    
            ],
            borderColor: [
                'rgb(178, 222, 39)'
            ],
            borderWidth: 1
          },
          {
            label: labelsLoc[4],
            data:[barGraphDatasets[barGraphSelected][0][4]],
            backgroundColor: [
                'rgb(255, 162, 132, 0.8)'
    
            ],
            borderColor: [
                'rgb(255, 162, 132)'
            ],
            borderWidth: 1
          }

        ]
        };
    
        setbarGraphData(dataBar)

    },[barGraphSelected, barGraphDatasets])

    useEffect(() => {
        if (initialRender === 1) {
          return
        }
        let query = areas.createQuery();
        
        query.geometry = context.state.clickEvent.ground.mapPoint;  // the point location of the pointer
        query.spatialRelationship = "intersects";  // this is the default
        query.returnGeometry = true;
        query.outFields = [ "*" ];

      
        areas.queryFeatures(query)
          .then(function(response){
            
            setLocation(response.features[0].attributes.seniunait)
            setLocationCoord({
                latitude: context.state.clickEvent.ground.mapPoint.latitude.toFixed(6),  
                longitude: context.state.clickEvent.ground.mapPoint.longitude.toFixed(6)
            })

            let query = healthReach.createQuery()
            let geometry = response.features[0].geometry
            query.geometry = geometry;  // the point location of the pointer
            query.spatialRelationship = "intersects";  // this is the default
            query.returnGeometry = true;
            query.outFields = [ "*" ];
    

            queryFeaturesOverLimit(healthReach, query).then((features) => {

                setHealthServices(features)
            })

            queryFeaturesOverLimit(health, query).then((features) => {

                setHealthBuildings(features)
            })

            setInitialRender(1)
          }); 
      },[initialRender])

    useEffect(() => {
        if (healtServices[0] === 1){
            updateGraphData()
        }
        if (location === null || healtServices[0] === 1 || healtServices.length === 0) {
            return
        }
        
        //console.log(healtServices)
        let filtered = healtServices.filter((feature) => {return feature.attributes.seniunait === location})
        setHealthServices([1,...filtered])
    },[location, healtServices])

    useEffect(() => {
        let tempTableData = [{string: "Sveikatos įstaigos"}]
        healtBuildings.forEach(biulding => {
            let name = biulding.attributes.Pavadinimas
            let suffix = "m"
            let target = {
                latitude: biulding.geometry.latitude,
                longitude:  biulding.geometry.longitude
            }
            let distance = getDistance(locationcord, target)


            tempTableData.push({string: name, data: distance, suffix:suffix})
            
        });
        setTableData1(tempTableData)

    },[healtBuildings])

    return (
        <div>
            <CordInput></CordInput>
            <div id={"healthInfoBoxContainer"}>
                <DynamicTable dataArray={tableData1}>

                </DynamicTable>
            </div>
           
            <div id={"healthButtonContainer"}>
                <div class={"healthButton ActiveBtn"} onClick={() => {
                    setBarGraphSelected(0)
                }}>
                    Bendrosios sveikatos paslaugos
                </div>
                <div class={"healthButton"} onClick={() => {
                    setBarGraphSelected(1)
                }}>
                    Tyrimai ir procedūros
                </div>
                <div class={"healthButton"} onClick={() => {
                    setBarGraphSelected(2)
                }}>
                    Dantų protezavimo paslaugos
                </div>
                
                <div class={"healthButton"} onClick={() => {
                    setBarGraphSelected(3)
                }}>
                    Dienos stacionaro paslaugos
                </div>
                
                <div class={"healthButton"} onClick={() => {
                    setBarGraphSelected(4)
                }}>
                    Greitoji medicinos pagalba
                </div>
                
                <div class={"healthButton"} onClick={() => {
                    setBarGraphSelected(5)
                }}>
                    I lygis
                </div>
                
                <div class={"healthButton"} onClick={() => {
                    setBarGraphSelected(6)
                }}>
                    II lygis
                </div>
                
                <div class={"healthButton"} onClick={() => {
                    setBarGraphSelected(7)
                }}>
                Prevencinės paslaugos
                </div>
            </div>
            
            <div id={"healthGraphContainer"}>
                <GarphBar dataset={barGraphData} indexAxis={'x'}>

                </GarphBar>
            </div>
        </div>
    )
}

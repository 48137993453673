import React, {useEffect, useRef} from 'react'
import { Chart, registerables  } from 'chart.js';
Chart.register(...registerables);

export const GraphLine = (props) => {
    let diagram = useRef(null);

    useEffect(() => {

        let chart = new Chart(diagram.current, {
            type: "line",
            data: props.dataset,
            options: {
                color: 'white',
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: props.title
                  }
                }
              },
          });

          return () => {
            chart.destroy();
          };
    }, [props])

  return (

            <canvas  ref={diagram}></canvas>

  )
}

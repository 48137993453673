import React, {useEffect, useRef} from 'react'
import { Chart, registerables  } from 'chart.js';
Chart.register(...registerables);

export const GraphPie = (props) => {
    let diagram = useRef(null);

    useEffect(() => {

        let chart = new Chart(diagram.current, {
            type: "pie",
            data: props.dataset,
            options: {
                color: 'white',
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: props.title,
                    color: "white"
                  }
                }
              },
          });

          return () => {
            chart.destroy();
          };
    }, [props])

  return (

            <canvas  ref={diagram}></canvas>

  )
}


import logo from './logo.svg';
import './App.css';
import { MapViewer } from './components/mapViewer';

import { useContext } from "react";
import { mapContext } from "./components/mapContext.js";


function App() {
  const context = useContext(mapContext);

  return (
    <div className="App">
      <MapViewer mapContext={context}></MapViewer>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import { GraphLine } from '../widgets/graphLine'
import { GarphBar } from '../widgets/garphBar'
import { CordInput } from '../widgets/cordInput'
import { DynamicTable } from '../widgets/dynamicTable'

import { areas, constructionNTR, solar } from '../../services/featureLayersOld'
import { useContext } from "react";
import { mapContext } from '../mapContext'
import { findNearestFromList, queryFeaturesOverLimit } from '../helper/helper'
import { GraphPie } from '../widgets/graphPie'
import { convertSpeed } from 'geolib'

export const EnergyTab = () => {
    const context = useContext(mapContext);
    const [initialRender, setInitialRender] = useState(0);

    const [buildings, setBuildings] = useState([]);
    const [solarData, setSolarData] = useState([]);

    let dataEmpty = {
        labels: [],
        datasets: []
      }

    const[lineGraphData, setLineGraphData] = useState(dataEmpty)
    const[pieGraphData1, setPieGraphData1] = useState(dataEmpty)
    const[pieGraphData2, setPieGraphData2] = useState(dataEmpty)

    const [tableData1, setTableData1] = useState([{string: "Seniūnaitija",
        data: "Minimalus saulės energijos potencialas (kWh/m2)", 
        suffix: "Maksimalus saulės energijos potencialas (kWh/m2)", 
        suffix2: "Vidutinis saulės energijos potencialas (kWh/m2)",
        suffix3: "Suminis saulės energijos potencialas (kWh/stogui)" 
    }])

    function updateLineGraph() {
        let biulidingConstructionDates = buildings.map(({attributes}) => (attributes.STATMETAI)).filter((year) => {return year != 0})
        //console.log(biulidingConstructionDates)

        const counts = {};

        biulidingConstructionDates.forEach(year => {
            counts[year] = counts[year] ? counts[year] + 1 : 1;
        });

        //console.log(counts);

        let dataLine = {
            labels:  Object.keys(counts),
            datasets: [
              {
                label: 'Pastatų statybos metai',
                data: Object.values(counts),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.8)',
              }
            ]
          };

        setLineGraphData(dataLine)
    }

    function updatePie1() {
        let biulidingRenovation = buildings.map(({attributes}) => (attributes.RENOVUOTA))
        console.log(biulidingRenovation)

        const counts = {};

        /*Renovuota is 10
        Renovuojama is 100
        Rengiamasi renovacijai is 110
        */

        biulidingRenovation.forEach(status => {
            let statusParsed
            if (status === null || status === 0 || status ===120){
                statusParsed = "Nera Informacijos"
            }
            if (status === 10){
                statusParsed = "Renovuota"
            }
            if (status === 100){
                statusParsed = "Renovuojama"
            }
            if (status === 110){
                statusParsed = "Rengiamasi renovacijai"
            }
            counts[statusParsed] = counts[statusParsed] ? counts[statusParsed] + 1 : 1;
        });

        

        const data = {
            labels: Object.keys(counts),
            datasets: [{
              label: 'Pastatai kvartale',
              data: Object.values(counts),
              backgroundColor: [
                'rgb(255, 99, 132, 0.8)',
                'rgb(54, 162, 235, 0.8)',
                'rgb(255, 205, 86, 0.8)',
                'rgb(178, 222, 39, 0.8)'
              ],
              borderColor: 'rgb(255, 99, 132, 0)',
              hoverOffset: 4
            }]
        };

        setPieGraphData1(data)
    }
    
    function updatePie2() {
        let biulidingEnergyClass = buildings.map(({attributes}) => (attributes.EN_KLASE))
        //console.log(biulidingEnergyClass)

        const counts = {};

        /*Renovuota is 10
        Renovuojama is 100
        Rengiamasi renovacijai is 110
        */

        biulidingEnergyClass.forEach(status => {
            if (status === null || status=== ""){
                status = "Nėra Informacijos"
            }
            counts[status] = counts[status] ? counts[status] + 1 : 1;
        });

        //log(counts)
        
        
        const data = {
            labels: Object.keys(counts),
            datasets: [{
              label: 'Pastatai kvartale',
              data: Object.values(counts),
              backgroundColor: [
                'rgb(255, 99, 132, 0.8)',
                'rgb(54, 162, 235, 0.8)',
                'rgb(255, 205, 132, 0.8)',
                'rgb(178, 222, 39, 0.8)',
                'rgb(255, 162, 132, 0.8)',
                'rgb(54, 205, 235, 0.8)',
                'rgb(255, 205, 86, 0.8)',
                'rgb(178, 99, 39, 0.8)',
                'rgb(162, 99, 132, 0.8)'
              ],
              borderColor: 'rgb(0, 0, 0, 0.4)',
              borderWidth: 1,
              hoverOffset: 4
            }]
        };

        setPieGraphData2(data)
    }

    function updateSoalrData() {
        let tableTemp = tableData1[1]
        //console.log(tableData1)
        let solarMinArr = solarData.map(({attributes}) => (attributes.SOLAR_yrly_sqm_min))
        let solarMaxArr = solarData.map(({attributes}) => (attributes.SOLAR_yrly_sqm_max))
        let solarMeanArr = solarData.map(({attributes}) => (attributes.SOLAR_yrly_sqm_mean))
        let solarSumArr = solarData.map(({attributes}) => (attributes.SOLAR_yrly_sum)) 

        let count = 0
        solarMinArr.forEach(instance => {
            if (parseInt(instance) > 0){
                count += parseInt(instance)
                //console.log(count)
            }
        });

        tableTemp.data = count
        count = 0

        solarMaxArr.forEach(instance => {
            if (parseInt(instance) > 0){
                count += parseInt(instance)
            }
        });
        
        tableTemp.suffix = count
        count = 0

        solarMeanArr.forEach(instance => {
            if (parseInt(instance) > 0){
                count += parseInt(instance)
            }
        });

        tableTemp.suffix2 = count
        count = 0

        solarSumArr.forEach(instance => {
            if (parseInt(instance) > 0){
                count += parseInt(instance)
            }
        });

        tableTemp.suffix3 = count

        setTableData1([tableData1[0], tableTemp])
    }


    useEffect(() => {
        if (initialRender === 1) {
          return
        }
        let query = areas.createQuery();
        
        query.geometry = context.state.clickEvent.ground.mapPoint;  // the point location of the pointer
        query.spatialRelationship = "intersects";  // this is the default
        query.returnGeometry = true;
        query.outFields = [ "*" ];

        let tableTemp = [tableData1[0],{
            string: null,
            data: null, 
            suffix: null, 
            suffix2: null,
            suffix3:  null
        }]
      
        areas.queryFeatures(query)
          .then(function(response){
            //console.log(response.features[0])
            tableTemp[1].string = response.features[0].attributes.seniunija + ", " + response.features[0].attributes.seniunait
            setTableData1(tableTemp)
           
            let querySolarinArea = solar.createQuery()
            let geometry = response.features[0].geometry
            querySolarinArea.geometry = geometry;  // the point location of the pointer
            querySolarinArea.spatialRelationship = "intersects";  // this is the default
            querySolarinArea.returnGeometry = true;
            querySolarinArea.outFields = [ "*" ];
    

            queryFeaturesOverLimit(solar, querySolarinArea).then((features) => {
                setSolarData(features)
            })

            queryFeaturesOverLimit(constructionNTR, querySolarinArea).then((features) => {
                
                setBuildings(features)
            })

            setInitialRender(1)
          }); 
      },[initialRender])

    useEffect(() => {
        updateLineGraph()
        updatePie1()
        updatePie2()
    },[buildings])


    useEffect(() => {
        if (solarData.length > 0 ){
            updateSoalrData()
        }
        
    },[solarData])
    return (
        <div>
            <div id={"energyLineGraphContainer"}>
                <GraphLine dataset = {lineGraphData} title = {""}></GraphLine>
            </div>
            <div id={"energyPieGraphContainer1"}>
                <GraphPie dataset = {pieGraphData1} title={"Pastatų renovacija"}></GraphPie>
            </div>
            <div id={"energyPieGraphContainer2"}>
                <GraphPie dataset = {pieGraphData2} title={"Pastatų energetinio naudingumo klasė"}></GraphPie>
            </div>
            <div id={"energySolarInfo"}>
                {solarData.length > 0  ? <DynamicTable dataArray={tableData1}></DynamicTable> : ""
                }
            </div>
        </div>
    )
}


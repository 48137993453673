import React, { useEffect, useState } from 'react'
import { GraphLine } from '../widgets/graphLine'
import { GarphBar } from '../widgets/garphBar'
import { CordInput } from '../widgets/cordInput'
import { DynamicTable } from '../widgets/dynamicTable'

import { areas, realEstate, areasPopulation } from '../../services/featureLayersOld'
import { useContext } from "react";
import { mapContext } from '../mapContext'
import { findNearestFromList, queryFeaturesOverLimit } from '../helper/helper'
import { GraphPie } from '../widgets/graphPie'
import { convertSpeed } from 'geolib'

export const PlanningTab = () => {
    const context = useContext(mapContext);
    const [initialRender, setInitialRender] = useState(0);

    const[buildings, setBuildings] = useState([])
    const[population, setPopulation] = useState([])

    const[location, setLocation] = useState()
    const[tableData, setTableData] = useState([
        {string: "Jūsų seniūnaitija: ", data: "-"},
        {string: "Bendras gyventojų skaičius: ", data: "-"}, 
        {string: "Bendras nepanaudotų pastatų kiekis (m²): ", data: "-"}])

    let dataBar = {
        labels: [],
        datasets: []
    }
    
    const [barGraphData, setbarGraphData] = useState(dataBar)
    const [pieGraphData, setPieGraphData] = useState(dataBar)

    function updateBuildingsData() {
        let biulidingSizeDates = buildings.map(({attributes}) => ({street: attributes.Gatve, size: attributes.Plotas_m2}))


        const counts = {};

        biulidingSizeDates.forEach(biulding => {
            counts[biulding.street] = counts[biulding.street] ? (parseFloat(counts[biulding.street]) + parseFloat(biulding.size)) : parseFloat(biulding.size);

            
        });

        let tempTable = tableData
        let filterBiulding = biulidingSizeDates.map(({size})  => ({size}));
        const sumValues = obj => Object.values(obj).reduce((a, b) => a + b["size"], 0);
        tempTable[2].data =  sumValues(filterBiulding).toFixed(2) + " m²"
        setTableData(tempTable)


        let dataBar = {
        labels: Object.keys(counts),
        datasets: [{
            label: 'Nepanaudotų pastatų kiekis m²',
            data: Object.values(counts),
            backgroundColor: [
            'rgba(255, 99, 132, 0.8)',

            ],
            borderColor: [
            'rgb(255, 99, 132)',
            ],
            borderWidth: 1
        }]
        };
        
        setbarGraphData(dataBar)

    }

    function updatePopulationData() {
        let populationData = population.filter((area) => {return area.attributes.Seniunait === location})


        let obj = {
          "0-6": populationData[0].attributes.AG_0_6,  
          "7-19": populationData[0].attributes.AG_7_19, 
          "20-30": populationData[0].attributes.AG_20_30,   
          "31-50": populationData[0].attributes.AG_31_50,  
          "51-64": populationData[0].attributes.AG_51_64,  
          "64+" :  populationData[0].attributes.AG_64plus
        };


        let tempTable = tableData
        const sumValues = obj => Object.values(obj).reduce((a, b) => parseInt(a) + parseInt(b), 0);
        tempTable[1].data = sumValues(obj)
        setTableData(tempTable)
        
        
        const data = {
            labels: Object.keys(obj),
            datasets: [{
              
              data: Object.values(obj),
              backgroundColor: [
                'rgb(255, 99, 132, 0.8)',
                'rgb(54, 162, 235, 0.8)',
                'rgb(255, 205, 132, 0.8)',
                'rgb(178, 222, 39, 0.8)',
                'rgb(255, 162, 132, 0.8)',
                'rgb(54, 205, 235, 0.8)'
              ],
              borderColor: 'rgb(0, 0, 0, 0.4)',
              borderWidth: 1,
              hoverOffset: 4
            }]
        };

        setPieGraphData(data)
        
    }

    useEffect(() => {
        if (initialRender === 1) {
          return
        }
        let query = areas.createQuery();
        
        query.geometry = context.state.clickEvent.ground.mapPoint;  // the point location of the pointer
        query.spatialRelationship = "intersects";  // this is the default
        query.returnGeometry = true;
        query.outFields = [ "*" ];

      
        areas.queryFeatures(query)
          .then(function(response){

            let tempTable = tableData
            tempTable[0].data = response.features[0].attributes.seniunija + ", " + response.features[0].attributes.seniunait

            setLocation(response.features[0].attributes.seniunait)

            let query = realEstate.createQuery()
            let geometry = response.features[0].geometry
            query.geometry = geometry;  // the point location of the pointer
            query.spatialRelationship = "intersects";  // this is the default
            query.returnGeometry = true;
            query.outFields = [ "*" ];
    

            queryFeaturesOverLimit(realEstate, query).then((features) => {
                setBuildings(features)
            })

            queryFeaturesOverLimit(areasPopulation, query).then((features) => {
                setPopulation(features)
            })

            setInitialRender(1)
          }); 
      },[initialRender])

    useEffect(() => {
        if (buildings.length > 0){
            updateBuildingsData()
        }
    },[buildings])

    useEffect(() => {
        if (population.length > 0){
            updatePopulationData()
        }
    },[population])

    return (
    <div>
        <div id={"planningRealEstateGraph"}>
            <GarphBar dataset={barGraphData} indexAxis={'y'}></GarphBar>
        </div>
        <div id={"planningPopulationGraph"}>
            <GraphPie dataset = {pieGraphData} title={"Gyventojų amžiaus statistika"}></GraphPie>
        </div>
        <div id={"planningPopulationTable"}>
            <DynamicTable dataArray = {tableData}></DynamicTable>
        </div>
    </div>
    )
}

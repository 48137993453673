import React, { useEffect, useState } from 'react'

export function DynamicTable (props)  {


  return (
        <table className="dynamicTable">
          <tbody>
            {

              props.dataArray.map((obj) => {
                return (
                  <tr>
                    <td>{obj.string}</td>
                    <td>{obj.data}</td>
                    <td>{obj.suffix}</td>
                    <td>{obj.suffix2}</td>
                    <td>{obj.suffix3}</td>
                    <td>{obj.suffix4}</td>
                    <td>{obj.suffix5}</td>
                  </tr>
                );
              })
              
            }
          </tbody>
        </table>
  )
}

import React, { useEffect, useState } from 'react'
import { GraphLine } from '../widgets/graphLine'
import { GarphBar } from '../widgets/garphBar'
import { CordInput } from '../widgets/cordInput'
import { DynamicTable } from '../widgets/dynamicTable'

import { areas, social } from '../../services/featureLayersOld'
import { useContext } from "react";
import { mapContext } from '../mapContext'
import { findNearestFromList, queryFeaturesOverLimit } from '../helper/helper'
import { GraphPie } from '../widgets/graphPie'
import { convertSpeed } from 'geolib'
import { formatLabel } from '../helper/helper'

export const SocialTab = () => {
    const context = useContext(mapContext);
    const [initialRender, setInitialRender] = useState(0);
    const [flag, setFlag] = useState(false)

    let graphTitle = {

      title: {
        display: true,
        text: "Socialinių paslaugų įstaigos",
        color: "white",
        position: "top"
      }
      
    }

    const[tableData, setTableData] = useState([
        {string:"Jūsų Seniūnaitija ", data:"-"},
        {string:"Artimiausias:"},
        {string:"Dienos centras ", data:"-" },
        {string:"Dienos socialinė globa suaugusiems ", data:"-" },
        {string:"Dienos socialinė globa vaikams ", data:"-" },
        {string:"Globos centras ", data:"-" },
        {string:"Krizių centras ", data:"-" },
        {string:"Paslaugos iš bausmės atlikimo vietų paleistiems asmenims ", data:"-" },
        {string:"Romų integracijos paslaugos ", data:"-" },
        {string:"Socialinė reabilitacija neįgaliesiems bendruomenėje ", data:"-" },
        {string:"Socialinės globos įstaiga ", data:"-" },
        {string:"Socialinis centras (dušai, pirtis, skalbykla) ", data:"-" },
        {string:"Socialinis centras (maitinimas nepasiturintiems) ", data:"-" },
        {string:"Vaikų dienos socialinė priežiūra ", data:"-" }
    ])

    const[location, setLocation] = useState()

    let dataBar = {
        labels: [],
        datasets: []
    }
    
    const [barGraphData, setbarGraphData] = useState(dataBar)

    useEffect(() => {
        if (initialRender === 1) {
          return
        }
        let query = areas.createQuery();
        query.geometry = context.state.clickEvent.ground.mapPoint;  // the point location of the pointer
        query.spatialRelationship = "intersects";  // this is the default
        query.returnGeometry = true;
        query.outFields = [ "*" ];
      
        areas.queryFeatures(query)
          .then(function(response){
            //console.log(response.features[0])
            let tempTable = tableData
            tempTable[0].data = response.features[0].attributes.seniunija + ", " + response.features[0].attributes.seniunait
            setTableData(tempTable)

            let query = social.createQuery()
            let geometry = response.features[0].geometry
            query.geometry = geometry;  // the point location of the pointer
            query.spatialRelationship = "intersects";  // this is the default
            query.returnGeometry = true;
            query.outFields = [ "*" ];
    
            social.queryFeatures(query).then(function(response) {
              
                //console.log(response.features[0].attributes)
                let tempTable = tableData
                let filtered
                let filterTypes = [
                    "Dienos centrai (sociokultūrinės paslaugos senjorams)",
                    "Dienos socialinė globa (suaugusiems su negalia)",
                    "Dienos socialinė globa (vaikams su negalia)",
                    "Globos centrai (pagalba globėjams)",
                    "Krizių centrai",
                    "Paslaugos iš bausmės atlikimo vietų paleistiems asmenims",
                    "Romų integracijos paslaugos",
                    "Socialinė reabilitacija neįgaliesiems bendruomenėje",
                    "Socialinės globos įstaigos (įstaigose žmonės gyvena)",
                    "Socialinis centras (dušai, pirtis, skalbykla)",
                    "Socialinis centras (maitinimas nepasiturintiems)",
                    "Vaikų dienos socialinė priežiūra",
                ]

                for (let i = 0; i <= 11; i++){
                    //log(response)
                    filtered = response.features.filter((x) => {return x.attributes.USER_Tipas === filterTypes[i]})
                    if (filtered.length > 0) {
                        let longLatList = filtered.map(({geometry}) => ({latitude: geometry.latitude, longitude: geometry.longitude}))
                        
                        
                        tempTable[i+2].data = findNearestFromList({
                            latitude: context.state.clickEvent.ground.mapPoint.latitude.toFixed(6),  
                            longitude: context.state.clickEvent.ground.mapPoint.longitude.toFixed(6) },
                            longLatList
                        ) + " m"
                    } else {
                        tempTable[i+2].data = "Nėra"
                        tempTable[i+2].suffix = ""
                    }
                    setTableData(tempTable)
                    setFlag(!flag)
                }
                    
                let labelsLoc = response.features.map(({attributes}) => (attributes.USER_Pavadinimai))
                for (let i = 0; i < labelsLoc.length; i++) {
                  //console.log(labelsLoc[i].length)
                  if (labelsLoc[i].length > 30) {
                    labelsLoc[i] = formatLabel(labelsLoc[i], 30)
                    //console.log(labelsLoc[i])

                  }
                }
                let datasetsSize = response.features.map(({attributes}) => (attributes.USER_Vietu_sk))
                let datasetsWaiting = response.features.map(({attributes}) => (attributes.USER_Laukianciuju_sk))
                //
                

                for (let i = 0; i < datasetsSize.length; i++){
                    if (datasetsSize[i] === 0){
                        labelsLoc.splice(i,1)
                        datasetsSize.splice(i,1)
                        datasetsWaiting.splice(i,1)
                        i--
                    }
                }

                let dataBar = {
                    labels: labelsLoc,
                    datasets: [{
                      label: 'Vietų skaičius',
                      data: datasetsSize,
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.8)',
              
                      ],
                      borderColor: [
                        'rgb(255, 99, 132)',
                      ],
                      borderWidth: 1
                    },
                    {
                      label: 'Laukiančiųjų skaičius',
                      data: datasetsWaiting,
                      backgroundColor: [
                        'rgba(135,206,235, 0.8)'
              
                      ],
                      borderColor: [
                        'rgb(135,206,235)'
                      ],
                      borderWidth: 1
                    }
                    ]
                  };
                  
                  setbarGraphData(dataBar)

            })
    
            setInitialRender(1)
          }); 
      },[initialRender])

      useEffect(() => {
        setTableData(tableData)
      },[flag])

    return (
        <div>
            <div id={"socialCordContainer"}>
                <CordInput></CordInput>
            </div>
           
            <div id={"socialTableContainer"}>
                <DynamicTable dataArray={tableData}></DynamicTable>
            </div>
            
            <div id={"socialGraphContainer"}>
                <GarphBar dataset={barGraphData} indexAxis={'y'} title={graphTitle}></GarphBar>
            </div>
            
        </div>
    )
}

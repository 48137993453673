import { getDistance } from 'geolib'
import { Chart } from "chart.js"

export function buildChart(chart, data, canvas, aspectRatio=1){
	if(chart == null){
		chart = new Chart(canvas, data)
		chart.options.maintainAspectRatio = false
		chart.options.aspectRatio = aspectRatio
	}else{
		chart.data = data.data
		chart.update()
	}
	return

}

export function findNearestFromList(location1, list){
    if(list.length == 0){
        return null
    }
    let nearestDistance = Number.MAX_SAFE_INTEGER
    list.forEach(
        location => {
            let loc = location

            if(Object.prototype.hasOwnProperty.call(location, "coordinates")){
                loc = {
                    latitude: location.coordinates[0],
                    longitude: location.coordinates[1],
                }
            }
            const distanceInMeters = getDistance(location1, loc)
            if(distanceInMeters < nearestDistance){
                nearestDistance = distanceInMeters
            }
        }
    )
    return Math.trunc(nearestDistance)
}


export async function queryFeaturesOverLimit(mapFeatureLayer, query) {
    //let totalCount = await mapFeatureLayer.queryFeatureCount(query)
    //console.log(totalCount)
    let i = true
    let counter = 0 
    let featuresArr = []
    while (i === true){
        let index =  {num:2000 , start: (counter*2000)}
        let tempQuer = query
        let indexedquery = Object.assign(tempQuer, index)
        let results =  await mapFeatureLayer.queryFeatures(indexedquery)
        
        featuresArr = featuresArr.concat(results.features)
        //console.log(results.features)
        if (results.features.length < 2000) {
            return featuresArr
        } 
        counter++;
    }
}

export function formatLabel(str, maxwidth){
    var sections = [];
    var words = str.split(" ");
    var temp = "";
  
    words.forEach(function(item, index){
      if(temp.length > 0)
      {
        var concat = temp + ' ' + item;
  
        if(concat.length > maxwidth){
          sections.push(temp);
          temp = "";
        }
        else{
          if(index == (words.length-1)) {
            sections.push(concat);
            return;
          }
          else {
            temp = concat;
            return;
          }
        }
      }
  
      if(index == (words.length-1)) {
        sections.push(item);
        return;
      }
  
      if(item.length < maxwidth) {
        temp = item;
      }
      else {
        sections.push(item);
      }
  
    });
  
    return sections;
}
import React from 'react'
import { mapContext } from '../mapContext.js';
import { useContext } from "react";



export function CordInput () {
  const context = useContext(mapContext);
  return (
    <div class="cordField">
        
        
        {"Jūsų kordinatės: " + context.state.clickEvent.ground.mapPoint.latitude.toFixed(4) + " " + context.state.clickEvent.ground.mapPoint.longitude.toFixed(4)}
        
	</div>
  )
}

import React, {useEffect, useRef} from 'react'
import { Chart, registerables  } from 'chart.js';
Chart.register(...registerables);

export const GarphBar = (props) => {
    let diagram = useRef(null);

    useEffect(() => {
      //console.log(props.title)
        let chart = new Chart(diagram.current, {
            type: "bar",
            data: props.dataset,
            options: {
                color: 'white',
                indexAxis: props.indexAxis,
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: { color: 'white', beginAtZero: true }
                    },
                    x: {
                      beginAtZero: true,
                      ticks: { color: 'white', beginAtZero: true }
                    }
                },
                plugins: props.title
            }
          });

          return () => {
            chart.destroy();
          };
    }, [props])

  return (

            <canvas  ref={diagram}></canvas>

  )
}

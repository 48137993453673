import FeatureLayer from "@arcgis/core/layers/FeatureLayer"

export const solar = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/VP_SDE1_VP3D_STATINIAI_3D_solar/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
   // renderer: renderInfo.extentOutline,
    visible: false
})

export const socialServices = new FeatureLayer({
    url: "https://opencity.vplanas.lt/arcgis/rest/services/VilniusData_portalas/Soc_paslaugu_istaigos/MapServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const health = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://services1.arcgis.com/usA3lHW20rGU6glp/arcgis/rest/services/Sveikatos_%C4%AEstaigos/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const educationEraly = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Ikimokyklinio_ugdymo_%c4%afstaigos/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const educationGeneral = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Mokyklos_Vilnius/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})


export const constructionAreas = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Pastatai_statyba_kvartalai/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const constructionNTR = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Pastatai_NTR_Seniunaitijos/FeatureServer/13",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})



export const realEstate = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Vilniaus_m_Nekilnojamas_turtas/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const healthReach = new FeatureLayer({
    //effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Paslaugos_pasiekiamumas_unikalios/FeatureServer/442",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: false
})

export const areas = new FeatureLayer({
   // effect:bloomStyle,
    url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Seniunaitijos/FeatureServer/0",
    outFields: ["*"],
    // definitionExpression:"date = date'07/01/2022'",
    // popupEnabled:false
    // popupTemplate: renderInfo.popUpStrava,
    //renderer: renderInfo.extentOutline,
    visible: true
})

export const areasPopulation = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Gyventojai_seni%C5%ABnaitijose/FeatureServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const areasPopulationConc = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/server/rest/services/Hosted/Gyventoj%C5%B3_koncentracija_seni%C5%ABnaitijose/FeatureServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })


export const bikesAreas = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/4",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const sidewalkAreas = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/5",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const security = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://gis.vplanas.lt/arcgis/rest/services/Interaktyvus_zemelapis/Civiline_sauga/MapServer/4",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const social = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/arcgis/rest/services/VilniusData_portalas/Soc_paslaugu_istaigos/MapServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })




 export const bikes2022 = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/3",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const bikes2023 = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/2",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const bikes2024 = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/1",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const bikes2025 = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://services1.arcgis.com/usA3lHW20rGU6glp/ArcGIS/rest/services/Dviraciu_takai_pagal_seniunaitijas_WFL1/FeatureServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const sviesoforai = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/arcgis/rest/services/VilniusData_portalas/Sviesoforu_garsiniai_signalai/MapServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const svieslentes = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://opencity.vplanas.lt/arcgis/rest/services/VilniusData_portalas/Svieslentes/MapServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const parkingDisabled = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://atviras.vplanas.lt/arcgis/rest/services/Parkingas/Neigaliuju_parkavimo_vietos/MapServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const parkingEV = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://atviras.vplanas.lt/arcgis/rest/services/Parkingas/Elektromobiliu_parkavimo_vietos_stoteles/MapServer/1",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

 export const chargingEV = new FeatureLayer({
    // effect:bloomStyle,
     url: "https://atviras.vplanas.lt/arcgis/rest/services/Parkingas/Elektromobiliu_parkavimo_vietos_stoteles/MapServer/0",
     outFields: ["*"],
     // definitionExpression:"date = date'07/01/2022'",
     // popupEnabled:false
     // popupTemplate: renderInfo.popUpStrava,
     //renderer: renderInfo.extentOutline,
     visible: true
 })

import React, { createContext, useReducer } from "react";

export const mapContext = createContext();

const initialState  = {
    mapView : {zoom: 0},
    popup: false,
    clickEvent: null,
    tab: "education",
    areaSelected: null,
}

const reducer = (state, action) => {
    if (action.mapView != null) {
        return {
            ...state,
            mapView: action.mapView
        }
    }
    if (action.areaSelected != null) {
        return {
            ...state,
            areaSelected: action.areaSelected
        }
    }
    if (action.popup != null) {
        return {
            ...state,
            popup: action.popup
        }
    }
    if (action.clickEvent != null) {
        return {
            ...state,
            clickEvent: action.clickEvent
        }
    }
    if (action.tab != null) {
        return {
            ...state,
            tab: action.tab
        }
    }
}

export function MapProvider(props) {
    const [state, dispatch] = useReducer (reducer, initialState)

    return <mapContext.Provider value={{ state, dispatch }}>{props.children}</mapContext.Provider>;
}
import React, {useRef, useEffect, useState} from 'react'

import esriConfig from "@arcgis/core/config";

import Basemap from "@arcgis/core/Basemap";
import WebMap from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import TileLayer from "@arcgis/core/layers/TileLayer";
import Map from "@arcgis/core/Map";
import SceneView from "@arcgis/core/views/SceneView.js";
import LayerList from "@arcgis/core/widgets/LayerList";
import esriId from "@arcgis/core/identity/IdentityManager.js";


import WebScene from "@arcgis/core/WebScene.js";

import "../styles/mapViewer.css";

import Popup from "./popup.js"
import { areas } from '../services/featureLayers.js';






export const MapViewer = ({mapContext, childComponents, vectorTileLayerId}) => {
    
    let mapDiv = useRef(null);
    const [initialRender, setInitialRender] = useState(0);
    const [popup, setPopup] = useState(0);

    async function createMap() {

        esriId.on("dialog-create", () => {
            esriId.dialog.when(() => {
                const dialogHtml = esriId.dialog.container;
                const cancelBtn = dialogHtml.childNodes[0].childNodes[2].childNodes[0].childNodes[3].childNodes[1];
                cancelBtn.click();
              });

        });


        esriConfig.portalUrl = "https://vplanas.maps.arcgis.com/";
        esriConfig.request.interceptors.push({
            urls: ["https://opencity.vplanas.lt/server/rest/services/Hosted/Seniunaitijos/FeatureServer"],
            error: function (error) {
                //console.log(error)
              },
    
            before: async function (params) {


                params.requestOptions.query = params.requestOptions.query || {};
            },
        })

        
            const arcgisMap = new WebScene({
                portalItem: {
                    id: "d9fb36174ad74d1c8077baaaf5511fd5"
                }
            })

            const sceneView = new SceneView({
                container:  mapDiv.current,
                map: arcgisMap,
                view: {
                    popup: {
                        autoOpenEnabled: false,
                    }
                },
                popup: null,
                camera: {
                    position: {
                        x: 25.2797,
                        y: 54.6872,
                        z: 10000
                    },
                    tilt: 0,
                }
            })

            const layerlist = new LayerList({
                view: sceneView,
            });
            // comment line bellow for PROD build
           // sceneView.ui.add(layerlist, "top-right");
            arcgisMap.add(areas);

            sceneView.on("click", async function (event) {
                mapContext.dispatch({
                    popup: !mapContext.state.popup,
                });
                if (mapContext.state.popup === true) {
                    return
                } else {
                    let data = await sceneView.hitTest(event);
                    mapContext.dispatch({
                        clickEvent: data,
                    });
                }
            });
    }

    function callPopup() {
        
    }

    useEffect(() => {
        
        if (mapDiv.current) {
            let shouldRender = initialRender;
            if( shouldRender === 0 ){
                //console.log("Render map")
                createMap()
                setInitialRender(1);
                

            }
        }

    },[initialRender])


    return (
        <div>
            <div className="mapDiv" ref={mapDiv} onClick={() => {
                
            }}>
           
            </div>
            <div>{mapContext.state.popup ? <Popup mapContext={mapContext}/> : ""}</div>
        </div>
    )
}
